<template>
    <div>
        <el-timeline>
            <el-timeline-item timestamp="2020/07/09" placement="top">
                <el-card>
                    <h2>更新： V0.0.1</h2>
                    <h3>new & improved</h3>
                    <p>1.项目开始</p>
                </el-card>
            </el-timeline-item>
        </el-timeline>
    </div>
</template>
<script>
export default {
    name: 'Version',
    data() {
        return {};
    }
};
</script>
<style lang="less" scoped>
</style>
